/* global window */
export default function setConfirmationForm () {
  const statusOptions = document.getElementById('vjs-status-field')
  const deliveryOptions = document.getElementById('vjs-delivery-options')
  const vatRegisteredOptions = document.getElementById('vjs-vat-registered-options')
  const firstShipmentType = document.querySelector('input[name="line_item[shipment_type]"]')
  const oneWeekDeliveryOption = document.getElementById('vjs-one-week-delivery')

  if (!statusOptions) { return }
  if (firstShipmentType) firstShipmentType.checked = true
  trackHotjarEvent()
  setElementsForSelectedDeliveryType()

  if (oneWeekDeliveryOption) oneWeekDeliveryOption.addEventListener('change', () => setupOneWeekFields())
  statusOptions.addEventListener('change', () => togglestatusForm())
  deliveryOptions.addEventListener('change', () => setElementsForSelectedDeliveryType())

  if (!vatRegisteredOptions) return
  vatRegisteredOptions.addEventListener('change', () => toggleVatInvoiceForm())
}

function toggleVatInvoiceForm () {
  const vatRegisteredChecked = document.querySelector('input[name="seller_account[vat_registered]"]:checked')
  if (!vatRegisteredChecked) return

  const vatRegisteredFields = document.getElementById('vjs-vat-registered-fields')
  if (!vatRegisteredFields) return

  if (vatRegisteredChecked.value === 'true') {
    vatRegisteredFields.classList.remove('hidden')
  } else {
    vatRegisteredFields.classList.add('hidden')
  }
}

function togglestatusForm () {
  const isItemConfirmed = document.getElementById('vjs-item-confirmed').checked
  const isItemCancelled = document.getElementById('vjs-item-cancelled').checked

  if (isItemConfirmed) {
    showConfirmed()
  } else if (isItemCancelled) {
    showCanceled()
  } else {
    resetForm()
  }
}

function setElementsForSelectedDeliveryType () {
  const shipmentType = document.querySelector('input[name="line_item[shipment_type]"]:checked')

  if (!shipmentType) return

  if (shipmentType.value === 'vinterior_delivery') {
    setFormForPartnership()
  } else {
    showElementsForOwnDelivery()
    setFormForOwnDelivery()
  }
}

function getPartnershipSpecificSections () {
  const partnershipGuidelines = document.getElementById('vjs-delivery-partnership-guidelines')
  const partnershipTermsAgreement = document.getElementById('vjs-partnership-terms-agreed')
  const partnershipCalculations = document.getElementById('vjs-proovia-calculations')
  const partnerShipCollectionDates = document.getElementById('vjs-preferred-collection-date')

  return [partnershipGuidelines, partnershipTermsAgreement, partnershipCalculations, partnerShipCollectionDates]
}

function hidePartnershipSpecificSections () {
  const partnershipSpecicSections = getPartnershipSpecificSections()

  partnershipSpecicSections.forEach(section => {
    if (section) section.classList.add('hidden')
  })
}

function showPartnershipSpecificSections () {
  const partnershipSpecicSections = getPartnershipSpecificSections()

  partnershipSpecicSections.forEach(section => {
    if (section) section.classList.remove('hidden')
  })
}

function getOwnDeliverySpecificSections () {
  const ownDeliveryGuidelines = document.getElementById('vjs-own-delivery-guidelines')
  const ownDeliveryCalculations = document.getElementById('vjs-own-calculations')
  const deliveryTimeEstimate = document.getElementById('vjs-delivery-time-estimate')

  return [ownDeliveryGuidelines, ownDeliveryCalculations, deliveryTimeEstimate]
}

function hideOwnDeliverySpecificSections () {
  const ownDeliverySpecicSections = getOwnDeliverySpecificSections()

  ownDeliverySpecicSections.forEach(section => {
    if (section) section.classList.add('hidden')
  })
}

function showOwnDeliverySpecificSections () {
  const ownDeliverySpecicSections = getOwnDeliverySpecificSections()

  ownDeliverySpecicSections.forEach(section => {
    if (section) section.classList.remove('hidden')
  })
}

function showElementsForOwnDelivery () {
  const deliveryTimeEstimateSelect = document.getElementById('vjs-delivery_time_estimate-select')

  hidePartnershipSpecificSections()
  showOwnDeliverySpecificSections()

  if (deliveryTimeEstimateSelect) deliveryTimeEstimateSelect.value = ''
}

function showPartnershipFormElements () {
  showPartnershipSpecificSections()
  hideOwnDeliverySpecificSections()
}

function setFormForPartnership () {
  const deliveryTimeEstimateValue = document.getElementById('vjs-delivery-time-value').value
  const deliveryPrice = document.getElementById('vjs-partnership-delivery-price')
  const deliveryTimeEstimate = document.getElementById('vjs-delivery-time-estimate')

  showPartnershipFormElements()

  if (deliveryTimeEstimate) deliveryTimeEstimate.classList.add('hidden')
  document.getElementById('vjs-delivery_time_estimate-select').value = deliveryTimeEstimateValue
  document.getElementById('vjs-delivery-price-value').value = deliveryPrice.value
}

function setFormForOwnDelivery () {
  const ownDeliveryPrice = document.getElementById('vjs-own-delivery-price')
  const deliveryTimeEstimate = document.getElementById('vjs-delivery-time-estimate')
  const oneWeekDeliveryChecked = document.querySelector('input[name="line_item[one_week_delivery]"]:checked')

  if (ownDeliveryPrice) {
    document.getElementById('vjs-delivery-price-value').value = ownDeliveryPrice.value
  }

  if (oneWeekDeliveryChecked) {
    if (oneWeekDeliveryChecked.value === 'true') {
      deliveryTimeEstimate.classList.add('hidden')
      document.getElementById('vjs-delivery_time_estimate-select').value = 'less_than_one_week'
    } else {
      deliveryTimeEstimate.classList.remove('hidden')
    }

    toggleDeliveryDisclaimerAndBadges()
  }
}

function setupOneWeekFields () {
  setElementsForSelectedDeliveryType()
  toggleDeliveryDisclaimerAndBadges()
}

function toggleDeliveryDisclaimerAndBadges () {
  const oneWeekDeliveryChecked = document.querySelector('input[name="line_item[one_week_delivery]"]:checked')
  const shipmentType = document.querySelector('input[name="line_item[shipment_type]"]:checked')
  const oneWeekDeliveryBadge = document.getElementById('vjs-one-week-delivery-badge')
  const oneWeekDeliveryDisclaimer = document.getElementById('vjs-one-week-delivery-disclaimer')

  if (oneWeekDeliveryChecked.value === 'true') {
    oneWeekDeliveryBadge.classList.remove('hidden')
    oneWeekDeliveryDisclaimer.classList.add('hidden')
  } else {
    oneWeekDeliveryBadge.classList.add('hidden')

    if (shipmentType.value === 'self_delivery') {
      oneWeekDeliveryDisclaimer.classList.remove('hidden')
    } else {
      oneWeekDeliveryDisclaimer.classList.add('hidden')
    }
  }
}

function getFormElements () {
  const confirmedForm = document.getElementById('vjs-item-confirmed-form')
  const cancelledForm = document.getElementById('vjs-item-cancelled-form')

  return { confirmedForm, cancelledForm }
}

function showConfirmed () {
  const { confirmedForm, cancelledForm } = getFormElements()

  confirmedForm.classList.remove('hidden')
  cancelledForm.classList.add('hidden')
}

function showCanceled () {
  const { confirmedForm, cancelledForm } = getFormElements()

  confirmedForm.classList.add('hidden')
  cancelledForm.classList.remove('hidden')
}

function resetForm () {
  const { confirmedForm, cancelledForm } = getFormElements()

  confirmedForm.classList.remove('hidden')
  cancelledForm.classList.remove('hidden')
}

function trackHotjarEvent () {
  if (window.hj) {
    window.hj('event', 'line_item_confirmation_page')
  }
}
