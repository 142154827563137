import { pushToDataLayer } from '../PushToDataLayer'
import { pushToAlgoliaAnalytics } from '../Algolia'
import { getLastImpression } from '../utils/impressions'
import {
  getQueryID,
  getQueryIndex,
  isBot
} from '../../utils/search/helpers'
import { trackAmplitudeEvent } from '../../analytics/CustomEvents'

export function trackSearchPreviewClick () {
  pushToDataLayer('ga - searchPreviewSuggestionClicked', {
    eventCategory: 'Search Preview',
    eventAction: 'click'
  })
}

export function trackAutocompleteClick (source, listingId) {
  pushToDataLayer('ce - autocompleteSuggestionClicked', {
    autocomplete: {
      clickSource: source,
      listingId: listingId
    }
  })
}

export function trackAutocompleteOpen () {
  pushToDataLayer('ce - autocomplete open')
}

export function trackSellClick (source) {
  pushToDataLayer('Sell Link Clicked', {
    ecommerce: {
      click: {
        clicked_element: source
      }
    }
  })
}

export function trackHomepageHeroClick (targetUrl) {
  trackAmplitudeEvent('Homepage Hero Clicked', {
    target_url: targetUrl
  })
}

export function trackHomepageClick (clickedElement, targetUrl) {
  trackAmplitudeEvent('Homepage Clicked', {
    clicked_element: clickedElement,
    target_url: targetUrl
  })
}

export function trackHomepageCuratedTilesClick (tileName) {
  pushToDataLayer('homepage tile click', {
    ecommerce: {
      click: {
        clicked_element: tileName
      }
    }
  })
}

export function trackMoreFromSellerClick () {
  pushToDataLayer('moreFromSellerSectionClicked (Vinterior)')
}

export function trackNavbarClick () {
  pushToDataLayer('navBarClicked')
}

export function trackOpenSearchFiltersMobile () {
  pushToDataLayer('mobile filter modal viewed (Vinterior)')
}

export function trackCloseSearchFiltersMobile () {
  pushToDataLayer('mobile filter modal closed (Vinterior)')
}

export function trackPDPWishlistButtonClick () {
  pushToDataLayer('PDP wishlist button clicked')
}

export function trackListingTileWishlistButtonClick (clickedListingID) {
  pushToDataLayer('Product listing tile wishlist button clicked', {
    clicked_listing_id: clickedListingID
  })
}

export function trackNavbarSearch (query, autocomplete, recent) {
  pushToDataLayer('Search (Vinterior)', {
    search: {
      query: query,
      autocomplete: autocomplete,
      recent: recent
    }
  })
}

export function trackTextHighlightedOrCopied (highlightedElement, highlightedElementAction) {
  pushToDataLayer('Text Highlighted / Copied (Vinterior)', {
    highlighted_element: highlightedElement,
    highlighted_element_action: highlightedElementAction
  })
}

export async function trackListingViewed () {
  if (isBot()) return
  const listingIdElement = document.querySelector('#vjs-listing-page')
  if (!listingIdElement) return
  const listingId = listingIdElement.value.toString()
  const indexUsed = await getQueryIndex(listingId)
  const algoliaAnalyticsProperties = {
    index: indexUsed || 'Listing_production',
    eventName: 'Product Viewed',
    objectIDs: [listingId]
  }
  const queryID = await getQueryID(listingId)
  if (queryID && queryID !== 'undefined') algoliaAnalyticsProperties.queryID = queryID
  pushToAlgoliaAnalytics('viewedObjectIDs', algoliaAnalyticsProperties)
}

export function trackSearchResultsViewed (queryID, query, filters, impressions, path) {
  pushToDataLayer('sp - search results', {
    search_results: {
      search_term: query,
      applied_filters: filters,
      search_results: impressions
    }
  })

  pushToDataLayer('url updated (Vinterior)', {
    search: {
      path: path,
      query: query.toLowerCase()
    }
  })

  if (!queryID || queryID === 'undefined') return
  const processedFilters = filters.filter(filter => filter.filter_value).map((filter) => {
    const filterName = encodeURIComponent(filter.filter_name)
    const filterValue = encodeURIComponent(filter.filter_value)
    return `${filterName}:${filterValue}`
  })

  if (processedFilters.length === 0) return
  while (processedFilters.length > 0) {
    // Algolia takes 10 filters max so we need to send multiple events to account for that
    const filterSlice = processedFilters.splice(0, 10)
    const algoliaAnalyticsProperties = {
      index: 'Listing_' + process.env.NODE_ENV, // TODO: Can be more precise with the index here? later
      eventName: 'PLP: Filter Viewed',
      queryID: queryID, // ignored in Algolia on viewedFilters event but kept around
      filters: filterSlice // e.g. ['category_hierarchy.lvl0:Furniture > Storage']
    }
    pushToAlgoliaAnalytics('viewedFilters', algoliaAnalyticsProperties)
  }
}

function trackSearchInteraction (searchInteractionProperties) {
  pushToDataLayer('sp - search_interaction', searchInteractionProperties)
}

// TODO: DRY later when doing mobile
function validAffinities () {
  return ['vibe_names', 'year_of_manufacture', 'main_material_name', 'color_name']
}

function getPersonalisedAffinities () {
  if (!window.neuralPersoAffinities) return []
  const filteredAffinities = window.neuralPersoAffinities.filter(record => validAffinities().includes(record.name))
  return filteredAffinities
}

function isRecommendedFilter (filterName, filterValue) {
  return getPersonalisedAffinities().slice(0, 5).filter(item => item.name === filterName && item.value === filterValue).length > 0
}

export function trackSearchResultsFiltered (queryID, filterName, filterValue, formattedParameters) {
  // TODO: Review deprecated
  pushToDataLayer('search filter (Vinterior)', {
    filter: {
      name: filterName,
      value: filterValue,
      params: formattedParameters
    }
  })

  trackSearchInteraction({
    /* eslint-disable */
    search_interaction: {
      interaction_type: 'filter_interaction',
      filter_name: filterName,
      filter_value: filterValue,
      search_query_id: queryID,
      sort_by_option_value: null,
      listing_position: null,
      listing_id: null,
      source: isRecommendedFilter ? 'Recommended' : null
    }
    /* eslint-enable */
  })

  const encodedFilterName = encodeURIComponent(filterName)
  const encodedFilterValue = encodeURIComponent(filterValue)
  if (encodedFilterValue === '') return
  const filter = `${encodedFilterName}:${encodedFilterValue}`
  const algoliaAnalyticsProperties = {
    queryID: queryID,
    index: 'Listing_' + process.env.NODE_ENV,
    eventName: 'PLP: Filter Clicked',
    filters: [filter]
  }
  pushToAlgoliaAnalytics('clickedFilters', algoliaAnalyticsProperties)
}

export function trackSearchResultsSorted (option) {
  const recentImpression = getLastImpression()
  if (!recentImpression) return

  // TODO: Review deprecated
  pushToDataLayer('Sort By Dropdown Change (Vinterior)', {
    sortByOption: option
  })

  trackSearchInteraction({
    /* eslint-disable */
    search_interaction: {
      interaction_type: 'sort_by',
      sort_by_option_value: option,
      search_query_id: recentImpression.search_query_id,
      filter_name: null,
      filter_value: null,
      listing_position: null,
      listing_id: null
    }
    /* eslint-enable */
  })
}

export async function trackSearchResultsClicked (clickType, queryID, position, listingId, listingAttributes) {
  trackSearchInteraction({
    /* eslint-disable */
    search_interaction: {
      interaction_type: clickType,
      listing_position: position,
      search_query_id: queryID,
      sort_by_option_value: null,
      filter_name: null,
      filter_value: null,
      listing_id: listingId
    },
    // This gets picked up as a variable in GTM
    // And set on search_interaction event as a Listing entity
    clicked_listing: {
      ...listingAttributes
    }
    /* eslint-enable */
  })

  const indexUsed = await getQueryIndex(listingId)
  const commonAlgoliaAnalyticsProperties = {
    index: indexUsed || 'Listing_production',
    objectIDs: [listingId.toString()]
  }

  if (queryID && queryID !== 'undefined') {
    const algoliaAnalyticsProperties = {
      eventName: 'productClick',
      queryID: queryID,
      positions: [position],
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('clickedObjectIDsAfterSearch', algoliaAnalyticsProperties)
  } else {
    const algoliaAnalyticsProperties = {
      eventName: 'productClick - no qID',
      ...commonAlgoliaAnalyticsProperties
    }
    pushToAlgoliaAnalytics('clickedObjectIDs', algoliaAnalyticsProperties)
  }
}
