
export default function initCrowdFundingPopUp () {
  const isLoggedIn = JSON.parse(document.querySelector('body').getAttribute('data-logged-in'))
  if (isLoggedIn) createAndAppendScript()
}

function createAndAppendScript () {
  const seedrsScript = document.createElement('script')
  seedrsScript.src = 'https://assets.seedrs.com/campaign-scripts/vinterior-v2.js'
  seedrsScript.async = true
  seedrsScript.onload = () => {
    window.seedrsScript.init()
  }
  document.body.appendChild(seedrsScript)
}
